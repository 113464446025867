import React, { useState, useEffect } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import logo from '../images/logo.png';
import '../../src/assets/css/app.css';
import Cookies from 'js-cookie';
import { getCookie } from '../utils/Utils';
import User from '../layout/header/dropdown/user/User';

export default function NavBar() {
    const [isScrolled, setIsScrolled] = useState(false);
    const [isNavCollapsed, setIsNavCollapsed] = useState(true);
    const location = useLocation();
    const [activeNavItem, setActiveNavItem] = useState(null);
    const [navs, setNavs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [logo, setLogo] = useState(null);
    const authToken = getCookie("authToken");

    useEffect(() => {
        const fetchBlogsFromAPI = async () => {
            try {
                const authToken = getCookie("authToken");

                const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/get-list-nav`, {
                    headers: {
                        authToken: authToken,
                    },
                    method: "POST",
                });

                if (response.ok) {
                    const responseData = await response.json();
                    if (responseData.status) {
                        setNavs(responseData.data.navs);
                        if (responseData.data.navs.length > 0) {
                            setLogo(responseData.data.navs[0].logo);
                        }
                    } else {
                    }
                } else {
                    console.log("Error fetching blogs:", response.statusText);
                }
            } catch (error) {
                console.error("Fetch error:", error);
                // throw error;
            } finally {
                setLoading(false);
            }
        };
        fetchBlogsFromAPI();
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY >= 30) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

    const handleNavLinkClick = (navItem) => {
        setActiveNavItem(navItem);
        setIsNavCollapsed(true);
    };

    const handleServiceClick = () => {
        setActiveNavItem("#service");
        setIsNavCollapsed(true);
    };

    const handleSignIn = () => {
        const now = new Date();
        const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000);
        Cookies.set("currentURL", window.location.href, {
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
            expires: expires,
        });
    };


    return (
        navs.length > 0 && (
            <div className={`hero-section ${isScrolled ? 'top-fixed' : ''}`}>
                <section className="section-header">
                    <nav className={`navbar navbar-expand-lg `}>
                        <div className="container-fluid">
                            <Link className="navbar-brand" to={`${process.env.REACT_APP_SITE_URL}`}>
                                {logo && (
                                    <div style={{
                                        width: '100%',
                                        maxWidth: '250px',
                                        height: '60px',
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>
                                        <img
                                            src={logo}
                                            alt="logo"
                                            style={{
                                                maxWidth: '100%',
                                                maxHeight: '100%',
                                                width: 'auto',
                                                height: 'auto',
                                                objectFit: 'contain'
                                            }}
                                        />
                                    </div>
                                )}
                            </Link>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={handleNavCollapse}>
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarSupportedContent" >
                                <ul className="navbar-nav mx-auto mb-2 mb-lg-0 me-0">
                                    <li className="nav-item">
                                        <NavLink to={'/'} className="nav-link fw-lighter" onClick={() => handleNavLinkClick('/')}>Home</NavLink>
                                    </li>
                                    {navs.map((navItem) => (
                                        <li className="nav-item" key={navItem.id}>
                                            <NavLink to={`${process.env.REACT_APP_SITE_URL}${navItem.link}`} className="nav-link fw-lighter">
                                                {navItem.nav}
                                            </NavLink>
                                        </li>
                                    ))}
                                    {/* <li className="nav-item mt-1 auth-buttons rounded-3">
                                    <a type="button"  href={`${process.env.REACT_APP_ACCOUNT_URL}`} className="btn crimson-text auth-buttons mt-1 fw-bold" onClick={handleSignIn}>Sign In</a>
                                </li>
                                <li className="nav-item ms-3 mt-1 auth-buttons rounded-3">
                                    <a type="button" href={`${process.env.REACT_APP_ACCOUNT_URL}register`} className="btn crimson-text auth-buttons mt-1 fw-bold">Sign Up</a>
                                </li> */}
                                    {/* {!authToken ? (
                                    <>
                                        <li className="nav-item mt-1 auth-buttons rounded-3 ms-3">
                                            <a type="button"  href={`${process.env.REACT_APP_ACCOUNT_URL}`} className="btn crimson-text auth-buttons mt-1 fw-bold fs-6" onClick={handleSignIn}>Sign In</a>
                                        </li>
                                        <li className="nav-item ms-3 mt-1 auth-buttons rounded-3">
                                            <a type="button" href={`${process.env.REACT_APP_ACCOUNT_URL}register`} className="btn crimson-text auth-buttons mt-1 fw-bold fs-6">Sign Up</a>
                                        </li>
                                    </>
                                ) : (
                                    <User />
                                )} */}
                                </ul>
                            </div>
                        </div>
                    </nav>
                </section>
            </div>
        )
    );
}
