import React from "react";
import "./Blog.css";
import { Link } from "react-router-dom";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export default function Blogitem(props) {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear().toString().substr(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${day}-${month}-${year}`;
  };
  return (
    <React.Fragment>
      <div className="row g-3 align-items-start">
        {props.position === "left" && (
          <div className="col-lg-5 col-md-6 col-12">
            {props.is_video ? (
              <video
                controls
                className="w-100"
                style={{ objectFit: 'cover', maxHeight: '100%' }}
              >
                <source src={props.video} type="video/mp4" />
              </video>
            ) : (
              <img
                src={props.Img}
                alt="Featurette Image"
                className="img-fluid mx-auto"
                style={{ objectFit: 'contain', maxHeight: '100%' }}
              />
            )}
          </div>
        )}
        <div className="col-lg-7 col-md-6 col-12">
          <Link to={`/Blogdetails/${props.id}`} className="text-decoration-none">
            <div>
              <h4 className={`fw-bold pb-2 ${process.env.REACT_APP_TEXT_COLOR} lh-1`}>
                {props.title}
              </h4>
            </div>
          </Link>
          <div className="container">
            <div className="row">
              <div className="col-6 d-flex justify-content-start p-0">
                <div className="d-flex align-items-center">
                  <i className={`bi bi-person-fill pe-2 ${process.env.REACT_APP_TEXT_COLOR}`}></i>
                  <p className={`${process.env.REACT_APP_TEXT_COLOR} mb-0`}>
                    {props.author}
                  </p>
                </div>
              </div>
              <div className="col-6 d-flex justify-content-end p-0">
                <div className="d-flex align-items-center">
                  <i className={`bi bi-calendar-date pe-2 ${process.env.REACT_APP_TEXT_COLOR}`}></i>
                  <p className={`${process.env.REACT_APP_TEXT_COLOR} mb-0`}>
                    {formatDate(props.created_at)}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <p className={`lead description ${process.env.REACT_APP_TEXT_COLOR}`}>
            {props.description}
            <Link
              to={`/Blogdetails/${props.id}`}
              className="ms-0"
              style={{ color: process.env.REACT_APP_READ_MORE_TEXT_COLOR }}
            >
              Read More
            </Link>
          </p>
        </div>

        {props.position === "right" && (
          <div className="col-lg-5 col-md-6 col-12">
            {props.is_video ? (
              <video
                controls
                className="w-100"
                style={{ objectFit: 'cover', maxHeight: '100%' }}
              >
                <source src={props.video} type="video/mp4" />
              </video>
            ) : (
              <img
                src={props.Img}
                alt="Featurette Image"
                className="img-fluid mx-auto"
                style={{ objectFit: 'contain', maxHeight: '100%' }}
              />
            )}
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
