import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, CardText } from 'reactstrap';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { Block } from '../../../Components/Component';
import img1 from '../../../images/personaimg/Temp/temp5.jpg';
import img2 from '../../../images/personaimg/Temp/temp6.jpg';
import img3 from '../../../images/personaimg/Temp/temp7.jpg';
import { ShimmerText, ShimmerTitle, ShimmerThumbnail } from 'react-shimmer-effects';
import placeholderimage from '../../../images/News/NewsDefault.png'

const settings = {
    className: "slider-init",
    slidesToShow: 1,
    centerMode: false,
    slidesToScroll: 1,
    infinite: true,
    arrows: false,
    dots: true,
    autoplay: false,
    autoplaySpeed: 8000,
    pauseOnHover: true,
};

const staticNewsData = [
    {
        id: 1,
        image: img1,
        title: "Harris-Trump debate looms as another historic pivot point",
        subtitle: "Trump’s reversal and calls for multiple debates tell an emerging truth about the election",
        text: "President Joe Biden had banked on June’s presidential debate turning around a race that was slipping away from him.Now, Donald Trump may be laying a similar bet after reversing himself by agreeing to debate on ABC next month as his new Democratic opponent enjoys surging momentum. Trump clearly doesn’t believe he’ll suffer the kind of debacle that ended Biden’s campaign, but his decision — and call for another two debates on NBC and Fox, which Vice President Kamala Harris has not agreed to — tells an emerging truth about the election.",
    },
    {
        id: 2,
        image: img2,
        title: "Pot of gold found in Turkey could be ancient Greek mercenary's life savings.",
        subtitle: "An excavation in Turkey has uncovered a hoard of gold coins known as Persian darics from the fifth century BC. Persian King Darius is depicted on the front of a daric (above), which is not from the discovery.",
        text: "The ongoing excavation of an ancient Greek city in western Turkey has uncovered artifacts and archaeological remains dating as far back as the fifth century BC — including a pot filled with an undisclosed number of valuable gold coins that was hidden away for more than 2,000 years. The coins are now in the hands of the Persian king Darius, who claims they are worth more than the sum of gold coins he holds in his possession.",
    },
    {
        id: 3,
        image: img3,
        title: "Breakthrough in renewable energy storage promises to revolutionize the industry",
        subtitle: "Innovative battery technology could make solar and wind power more reliable",
        text: "Scientists have developed a new type of battery that can store large amounts of renewable energy for longer periods, potentially solving one of the biggest challenges facing the renewable energy sector...",
    }
];

const Hero = ({ limit = 3 }) => {
    const [newsData, setNewsData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchNewsData = async () => {
            const params = {
                limit: limit,
                order: 'desc',
                page: 1,
            };
            const queryString = new URLSearchParams(params).toString();
            const url = `${process.env.REACT_APP_NEWS_API_URL}api/v1/public/get-public-news?${queryString}`;

            try {
                const response = await fetch(url, {
                    method: 'GET',
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const data = await response.json();

                if (data.data && Array.isArray(data.data.news) && data.data.news.length > 0) {
                    const limitedData = data.data.news.slice(0, limit).map((news) => ({
                        id: news.id,
                        image: news.image || '../../../images/News/NewsDefault.png',
                        title: news.title,
                        text: news.news_text || '',
                        url: news.url,
                    }));
                    setNewsData(limitedData);
                } else {
                    setNewsData(staticNewsData.slice(0, limit));
                }
            } catch (error) {
                console.error("Error fetching news data:", error);
                setNewsData(staticNewsData.slice(0, limit));
            } finally {
                setLoading(false);
            }
        };

        fetchNewsData();
    }, [limit]);

    const placeholderImage = placeholderimage;

    const handleImageError = (event) => {
        event.target.src = placeholderImage;
    };

    const truncateText = (text, maxLength) => {
        if (!text || text.length <= maxLength) return text;
        return text.substr(0, maxLength) + '...';
    };

    const renderShimmer = () => (
        <div className="container h-100 pb-2" id='Home_element'>
            <Block>
                <Slider {...settings}>
                    {[...Array(limit)].map((_, index) => (
                        <div key={index}>
                            <div className='position-relative px-md-3 px-0'>
                                <ShimmerThumbnail height={400} width={1200} />
                                <div className=''>
                                    <Card color="light" className='card-overlay shadow-lg rounded-0'>
                                        <CardBody className="text-center">
                                            <CardHeader className='bg-transparent fs-3 ff text-black fw-semibold'>
                                                <ShimmerTitle line={1} />
                                            </CardHeader>
                                            <CardText className='ff text-start d-none d-sm-inline-block'>
                                                <ShimmerText line={3} />
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </Block>
        </div>
    );

    if (loading) {
        return renderShimmer();
    }

    return (
        <div className="container h-100 py-2" id='Home_element'>
            <Block>
                <Slider {...settings}>
                    {newsData.map((news, index) => (
                        <div key={news.id}>
                            <Link to={`/news/${news.id}`} className='slick_height'>
                                <div className='position-relative px-md-3 px-0'>
                                    <img src={news.image || placeholderImage} alt={news.title} width={'100%'} height={'550px'} onError={handleImageError} />
                                    <Card className='card-overlay shadow-sm rounded-0 pt-1' style={{ backgroundColor: '#FFF6F4' }}>
                                        <CardBody className="text-center">
                                            <CardHeader className='bg-transparent text-black fs-3 py-0 px-2 ff fw-semibold'>
                                                {news.title}
                                            </CardHeader>
                                            <CardText className='ff p-2 text-start d-inline-block'>
                                                {typeof news.text === 'string' ? truncateText(news.text, 250) : ''}
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </div>
                            </Link>
                        </div>
                    ))}
                </Slider>
            </Block>
        </div>
    );
};

export default Hero;
